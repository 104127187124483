import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Image from "./image"
import Hidden from "@material-ui/core/Hidden"
import Bar from "./bar"
import { Link } from "gatsby"

const AppsPanel = () => {
  return (
    <Grid
      container
      spacing={3}
      style={{ minHeight: 500 }}
      data-sal="slide-up"
      data-sal-delay="0"
      data-sal-easing="ease"
      data-sal-duration="700"
    >
      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Typography variant="h4" color="inherit">
          Preguntas frecuentes
        </Typography>
        <Bar />
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 30, marginTop: 20 }}
        >
          Accede a la informacion detallada del modo de uso y funcionamiento de
          nuestro kit para test covid.
        </Typography>
        <Link to="/preguntas-frecuentes">
          <Button variant="outlined" color="primary" size="large">
            Ir a las preguntas frecuentes
          </Button>
        </Link>
      </Grid>

      <Grid
        item={true}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ padding: 10 }}
      >
        <div style={{ width: "100%" }}>
          <Image
            alt="Virtual Healthcare for you"
            filename="apps-panel-image.png"
          />
        </div>
      </Grid>
    </Grid>
  )
}
export default AppsPanel
