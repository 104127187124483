import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Bar from "./bar"
import covidVideo from "../videos/covid.mp4"

const AboutPanel = () => {
  return (
    <Grid
      container
      spacing={3}
      style={{ minHeight: 500 }}
      data-sal="slide-up"
      data-sal-delay="-"
      data-sal-easing="ease"
      data-sal-duration="700"
    >
      <Grid
        item={true}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ padding: 10 }}
      >
        <video autoPlay loop muted controls>
          <source src={covidVideo} type="video/mp4" />
          Tu navegador no admite el elemento <code>Video</code>.
        </video>
      </Grid>
      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Typography variant="h4" color="inherit">
          Fabricado con los más altos estandares.
        </Typography>
        <Bar />
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 30, marginTop: 20 }}
        >
          Fabricado en instalaciones de primera generación y puesto a tu
          disposicion por nuestros proveedores para que puedas acceder a el de
          forma eficiente.
        </Typography>
      </Grid>
    </Grid>
  )
}
export default AboutPanel
