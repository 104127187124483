import React, { useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import sal from "sal.js"
import Image from "./image"
import Hidden from "@material-ui/core/Hidden"
import { Link } from "gatsby"

const Hero = () => {
  useEffect(() => {
    sal()
  }, [])
  return (
    <Grid container spacing={3} style={{ minHeight: 500 }}>
      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Typography variant="h4" color="inherit" style={{ marginBottom: 20 }}>
          Kit de prueba
        </Typography>

        <Typography variant="subtitle1" style={{ marginBottom: 30 }}>
          Para reafirmar tu seguridad y la de los tuyos. Trabajamos en poner a
          tu disposición nuestros kits de pruebas para covid profesionales de
          alta calidad. Disponibles por medio de nuestros proveedores asociados.
        </Typography>
        <Link to="/funciona">
          <Button variant="contained" color="primary" size="large">
            Saber más
          </Button>
        </Link>
      </Grid>
      <Grid
        item={true}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ padding: 10 }}
      >
        <div style={{ width: "100%" }}>
          <Image alt="Virtual Healthcare for you" filename="kitCovid.png" />
        </div>
      </Grid>
    </Grid>
  )
}
export default Hero
