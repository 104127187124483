import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ServiceCard from "./service-card"
import Bar from "./bar"

const Services = () => {
  let services = [
    {
      id: 1,
      title: "Sin necesidad de un laboratorio",
      description:
        "Para usar nuestro kit no es necesario contar con equipamento especializado.",
    },
    {
      id: 2,
      title: "Resultados rapidos",
      description: "Obten los resultados de tu prueba en menos de 15 minutos.",
    },
    {
      id: 3,
      title: "Certeza",
      description:
        "Resultados de gran cofiabilidad a prueba de falsos positivos.",
    },
  ]

  return (
    <Grid
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 10,
      }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5">
          Beneficios y características de nuestro kit
        </Typography>
        <Bar />
        <Typography variant="subtitle1" style={{ margin: 30 }}></Typography>
      </Grid>

      {services.map(service => (
        <Grid
          item
          xs={12}
          sm={4}
          key={service.id}
          data-sal="slide-right"
          data-sal-delay="0"
          data-sal-easing="ease"
          data-sal-duration="700"
        >
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  )
}
export default Services
